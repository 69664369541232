import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useStudentsList() {

    // Use toast
    const toast = useToast();

    const refStudentListTable = ref(null);

    const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
        { key: 'student', sortable: true, label: 'PERSONAL' },
        { key: 'document_number', sortable: true, label: 'NÚMERO DOCUMENTO' },
        { key: 'phone', sortable: true, label: 'TELÉFONO CELULAR' },
        { key: 'user_email', sortable: true, label: 'CORREO ELECTRÓNICO' },
        { key: 'inscriptions_count', sortable: true, label: 'CANTIDAD <br> SERVICIOS INSCRITOS', ...styleColumns },
        { key: 'createdAt', sortable: true, label: 'FECHA REGISTRO', ...styleColumns },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
    ];

    const perPage = ref(10);
    const totalStudents = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refStudentListTable.value ? refStudentListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalStudents.value
        }
    });

    const refetchData = () => {
        refStudentListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchStudents = (ctx, callback) => {
        store
            .dispatch('app-student/fetchStudents', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value
            })
            .then(response => {
                const { students, total } = response.data;
                callback(students);
                totalStudents.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista del personal',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchStudents,
        tableColumns,
        perPage,
        currentPage,
        totalStudents,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refStudentListTable,

        refetchData
    }
}
