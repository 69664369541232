import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchStudents(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/contractors/students', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addStudent(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/contractors/students/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateStudent(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/contractors/students/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteStudent(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/contractors/students/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}